/*
 * ContactUsPage Messages
 *
 * This contains all the text for the ContactUsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ContactUsPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Liên hệ với chúng tôi',
  },
  findOutAboutTheService: {
    id: `${scope}.findOutAboutTheService`,
    defaultMessage: 'Tìm hiểu dịch vụ',
  },
  alreadyHasAccount: {
    id: `${scope}.alreadyHasAccount`,
    defaultMessage: 'Đã có tài khoản?',
  },
  businessNotice: {
    id: `${scope}.businessNotice`,
    defaultMessage:
      'Hiện tại chúng tôi chỉ phục vụ các doanh nghiệp F&B (Nhà hàng, khách sạn, cửa hàng coffee, bếp online, ...)',
  },
  sendSuccessfully: {
    id: `${scope}.sendSuccessfully`,
    defaultMessage:
      'Thông tin đã được gửi thành công! Hệ thống đang tạo tài khoản của bạn và liên hệ cho bạn khi hoàn thành',
  },
  agreementPrompt: {
    id: `${scope}.agreementPrompt`,
    defaultMessage: 'Bằng việc nhấn Gửi, bạn đã đồng ý với',
  },
  terms: {
    id: `${scope}.terms`,
    defaultMessage: 'Điều khoản sử dụng',
  },
  privacyCondition: {
    id: `${scope}.privacyCondition`,
    defaultMessage: 'Chính sách bảo mật',
  },
});
